import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { HeartIcon } from '@/components/_svg/icons/HeartIcon'
import { useSWRAuth } from '@/hooks/swr/useSwr-auth'
import {
  favoriteLesson,
  favoritePlace,
  favoriteSerie,
  favoriteTeacher,
  favoriteVideo,
  favoriteVideoGroup,
  unfavorite,
  unfavoriteVideo,
  unfavoriteVideoGroup,
} from '@/services/api-rest'
import {
  IFavoritable,
  ILessonDecorated,
  IPlaceDecorated,
  ISerieDecorated,
  ITeacherDecorated,
  IVideoDecorated,
  IVideoGroupDecorated,
} from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  lesson?: ILessonDecorated
  lessonSerie?: ILessonDecorated
  // lessonTeacher?: ILessonDecorated
  // lessonPlace?: ILessonDecorated
  serie?: ISerieDecorated
  teacher?: ITeacherDecorated
  place?: IPlaceDecorated
  video?: IVideoDecorated
  videoGroup?: IVideoGroupDecorated
  color?: string
  className?: string
}

export const FavoriteToggler: React.FC<IProps> = (props) => {
  const { authLoading, authenticated } = useSWRAuth()
  const intl = useIntl()

  if (authLoading || !authenticated) return null

  const [mainItemProps, toggleFavorite, childItemProps] = getTargetedItem(props)

  return (
    <StyledButton
      onClick={(e) => {
        e.preventDefault()
        toggleFavorite(mainItemProps)
      }}
      title={intl.formatMessage(
        {
          defaultMessage:
            '{favorite, select, true {Retirer des favoris} other {Ajouter aux favoris}}',
          description: 'FavoriteToggler: remove/add title',
        },
        {
          favorite: !!(childItemProps ?? mainItemProps).authUserFavorite,
        }
      )}
      className={props.className}
    >
      <StyledHeartIcon
        full={!!(childItemProps ?? mainItemProps).authUserFavorite}
        $color={props.color}
      />
    </StyledButton>
  )
}

const getTargetedItem = (
  props: IProps
): [IFavoritable, (arg: IFavoritable) => void, IFavoritable?] => {
  if (props.lesson) {
    return [props.lesson, toggleFavoriteLesson]
  }
  // Special cases to favorite/unfavorite lesson children
  if (props.lessonSerie) {
    return [props.lessonSerie, toggleFavoriteLessonSerie, props.lessonSerie.serie]
  }
  // if (props.lessonTeacher) {
  //   return [props.lessonTeacher, toggleFavoriteLessonTeacher, props.lessonTeacher.teacher]
  // }
  // if (props.lessonPlace) {
  //   return [props.lessonPlace, toggleFavoriteLessonPlace, props.lessonPlace.place]
  // }

  if (props.serie) {
    return [props.serie, toggleFavoriteSerie]
  }
  if (props.teacher) {
    return [props.teacher, toggleFavoriteTeacher]
  }
  if (props.place) {
    return [props.place, toggleFavoritePlace]
  }
  if (props.video) {
    return [props.video, toggleFavoriteVideo]
  }
  if (props.videoGroup) {
    return [props.videoGroup, toggleFavoriteVideoGroup]
  }
}

const toggleFavoriteLesson = async (lesson: ILessonDecorated) => {
  if (lesson.authUserFavorite) {
    if (lesson.authUserFavorite.ulid === 'wait') return

    lesson.mutate(
      unfavorite({ favoriteUuid: lesson.authUserFavorite.ulid }) as Promise<any>,
      {
        optimisticData: { ...lesson, authUserFavorite: null },
        populateCache: false,
        throwOnError: false,
      }
    )

    return
  }

  lesson.mutate(favoriteLesson({ lessonIri: lesson.id }) as Promise<any>, {
    optimisticData: {
      ...lesson,
      authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
    },
    populateCache: false,
    throwOnError: false,
  })
}

const toggleFavoriteLessonSerie = async (lesson: ILessonDecorated) => {
  if (lesson.serie.authUserFavorite) {
    if (lesson.serie.authUserFavorite.ulid === 'wait') return

    lesson.mutate(
      unfavorite({ favoriteUuid: lesson.serie.authUserFavorite.ulid }) as Promise<any>,
      {
        optimisticData: {
          ...lesson,
          serie: { ...lesson.serie, authUserFavorite: null },
        },
        populateCache: false,
        throwOnError: false,
      }
    )

    return
  }

  lesson.mutate(favoriteSerie({ serieIri: lesson.serie.id }) as Promise<any>, {
    optimisticData: {
      ...lesson,
      serie: {
        ...lesson.serie,
        authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
      },
    },
    populateCache: false,
    throwOnError: false,
  })
}

// const toggleFavoriteLessonTeacher = async (lesson: ILessonDecorated) => {
//   if (lesson.teacher.authUserFavorite) {
//     if (lesson.teacher.authUserFavorite.ulid === 'wait') return

//     lesson.mutate(
//       unfavorite({ favoriteUuid: lesson.teacher.authUserFavorite.ulid }) as Promise<any>,
//       {
//         optimisticData: {
//           ...lesson,
//           teacher: { ...lesson.teacher, authUserFavorite: null },
//         },
//         populateCache: (_, currentData) => ({
//           ...currentData,
//           teacher: { ...currentData.teacher, authUserFavorite: null },
//         }),
//       }
//     )

//     return
//   }

//   lesson.mutate(favoriteTeacher({ teacherIri: lesson.teacher.id }) as Promise<any>, {
//     optimisticData: {
//       ...lesson,
//       teacher: {
//         ...lesson.teacher,
//         authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
//       },
//     },
//     populateCache: (_, currentData) => ({
//       ...currentData,
//       teacher: {
//         ...currentData.teacher,
//         authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
//       },
//     }),
//   })
// }

// const toggleFavoriteLessonPlace = async (lesson: ILessonDecorated) => {
//   if (lesson.place.authUserFavorite) {
//     if (lesson.place.authUserFavorite.ulid === 'wait') return

//     lesson.mutate(
//       unfavorite({ favoriteUuid: lesson.place.authUserFavorite.ulid }) as Promise<any>,
//       {
//         optimisticData: {
//           ...lesson,
//           place: { ...lesson.place, authUserFavorite: null },
//         },
//         populateCache: (_, currentData) => ({
//           ...currentData,
//           place: { ...currentData.place, authUserFavorite: null },
//         }),
//       }
//     )

//     return
//   }

//   lesson.mutate(favoritePlace({ placeIri: lesson.place.id }) as Promise<any>, {
//     optimisticData: {
//       ...lesson,
//       place: {
//         ...lesson.place,
//         authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
//       },
//     },
//     populateCache: (_, currentData) => ({
//       ...currentData,
//       place: {
//         ...currentData.place,
//         authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
//       },
//     }),
//   })
// }

const toggleFavoriteSerie = async (serie: ISerieDecorated) => {
  if (serie.authUserFavorite) {
    if (serie.authUserFavorite.ulid === 'wait') return

    serie.mutate(
      unfavorite({ favoriteUuid: serie.authUserFavorite.ulid }) as Promise<any>,
      {
        optimisticData: { ...serie, authUserFavorite: null },
        populateCache: false,
        throwOnError: false,
      }
    )

    return
  }

  serie.mutate(favoriteSerie({ serieIri: serie.id }) as Promise<any>, {
    optimisticData: {
      ...serie,
      authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
    },
    populateCache: false,
    throwOnError: false,
  })
}

const toggleFavoriteTeacher = async (teacher: ITeacherDecorated) => {
  if (teacher.authUserFavorite) {
    if (teacher.authUserFavorite.ulid === 'wait') return

    teacher.mutate(
      unfavorite({ favoriteUuid: teacher.authUserFavorite.ulid }) as Promise<any>,
      {
        optimisticData: { ...teacher, authUserFavorite: null },
        populateCache: false,
        throwOnError: false,
      }
    )

    return
  }

  teacher.mutate(favoriteTeacher({ teacherIri: teacher.id }) as Promise<any>, {
    optimisticData: {
      ...teacher,
      authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
    },
    populateCache: false,
    throwOnError: false,
  })
}

const toggleFavoritePlace = async (place: IPlaceDecorated) => {
  if (place.authUserFavorite) {
    if (place.authUserFavorite.ulid === 'wait') return

    place.mutate(
      unfavorite({
        favoriteUuid: place.authUserFavorite.ulid,
      }) as Promise<any>,
      {
        optimisticData: { ...place, authUserFavorite: null },
        populateCache: false,
        throwOnError: false,
      }
    )

    return
  }

  place.mutate(favoritePlace({ placeIri: place.id }) as Promise<any>, {
    optimisticData: {
      ...place,
      authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
    },
    populateCache: false,
    throwOnError: false,
  })
}

const toggleFavoriteVideo = async (video: IVideoDecorated) => {
  if (video.authUserFavorite) {
    if (video.authUserFavorite.ulid === 'wait') return

    video.mutate(unfavoriteVideo({ videoIri: video.id }) as Promise<any>, {
      optimisticData: { ...video, authUserFavorite: null },
      populateCache: false,
      throwOnError: false,
    })

    return
  }

  video.mutate(favoriteVideo({ videoIri: video.id }) as Promise<any>, {
    optimisticData: {
      ...video,
      authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
    },
    populateCache: false,
    throwOnError: false,
  })
}

const toggleFavoriteVideoGroup = async (videoGroup: IVideoGroupDecorated) => {
  if (videoGroup.authUserFavorite) {
    if (videoGroup.authUserFavorite.ulid === 'wait') return

    videoGroup.mutate(
      unfavoriteVideoGroup({ videoGroupIri: videoGroup.id }) as Promise<any>,
      {
        optimisticData: { ...videoGroup, authUserFavorite: null },
        populateCache: false,
        throwOnError: false,
      }
    )

    return
  }

  videoGroup.mutate(
    favoriteVideoGroup({ videoGroupIri: videoGroup.id }) as Promise<any>,
    {
      optimisticData: {
        ...videoGroup,
        authUserFavorite: { ulid: 'wait', createdAt: new Date().toISOString() },
      },
      populateCache: false,
      throwOnError: false,
    }
  )
}

const StyledButton = styled.div`
  box-sizing: content-box;
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
  z-index: 1;

  &:hover {
    cursor: pointer;

    & > svg {
      fill: ${({ theme }) => theme.color.terracotaDark};
    }
  }
`
const StyledHeartIcon = styled(HeartIcon)<{ $color: string }>`
  display: block;
  fill: ${({ theme, $color }) => $color ?? theme.color.terracota};
  height: 100%;
  width: 100%;
`
const RoundFavoriteToggler = styled(FavoriteToggler)`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  box-shadow: 0 4px 12px 6px rgb(12 8 8 / 3%);
  padding: 12px;
`
export const TeacherFavoriteToggler = styled(RoundFavoriteToggler)`
  position: absolute;
  right: calc(15% - ${({ theme }) => theme.spacing.ms});
  top: calc(15% - ${({ theme }) => theme.spacing.ms});
`
export const InlineFavoriteToggler = styled(RoundFavoriteToggler)`
  display: inline-block;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }
`
export const LessonListItemFavoriteToggler = styled(FavoriteToggler)`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    position: absolute;
    right: ${({ theme }) => theme.spacing.s};
    top: ${({ theme }) => theme.spacing.s};
  }
`
