import { ITeacherFiltersState } from '@/reducers/teacherFilters'
import { IAreaKey, ITeacher, ITeacherDecorated } from '@/types/main'
import { fetchGraphQL } from '@/utils/api-helpers'

import { IGQLPageVariables, IPageGQLResults } from './api-graphql'

export type ITeacherGQLResults = IPageGQLResults<ITeacher[]>
export type ITeacherDecoratedGQLResults = IPageGQLResults<ITeacherDecorated[]>

export type ITeacherGQLQuery =
  | 'LessonOnsite'
  | 'LessonOnline'
  | 'Video'
  | 'VideoLessonOnline'
  | 'Random'
  | 'RandomLessonOnsite'
  | 'RandomLessonOnline'
  | 'RandomVideo'
  | 'RandomVideoLessonOnline'

export interface ITeacherResultsVariables extends IGQLPageVariables {
  gqlQuery?: ITeacherGQLQuery
  areaKey?: IAreaKey
}
export const getTeacherResults = async (
  variables: ITeacherResultsVariables = {}
): Promise<ITeacherGQLResults> => {
  const { gqlQuery = '', ...gqlVariables } = variables

  const query = `
    query Teacher${gqlQuery}Results (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 30
      $areaKey: String
      $multiSearch: String
      $order_: [TeacherFilter_order]
    ) {
      teachers: collectionPageQuery${gqlQuery}Teachers (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        area: $areaKey
        multiSearch: $multiSearch
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_TEACHER_FIELDS}
          area
          disciplinesOnly
          scope
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    teachers: ITeacherGQLResults
  }>(query, gqlVariables)

  return graphqlResult.data.teachers
}

export interface ITeacherResultVariables {
  teacherId: number
}
export const getTeacherResult = async (
  variables: ITeacherResultVariables
): Promise<ITeacher> => {
  const query = `
    query TeacherResult (
      $teacherId: Int!
    ) {
      teachers: collectionPageQueryTeachers (
        id: $teacherId
      ) {
        collection {
          ${MINIMAL_TEACHER_FIELDS}
          area
          disciplinesOnly
          scope
          bio
          facebookUrl
          instagramUrl
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    teachers: ITeacherGQLResults
  }>(query, variables)

  return graphqlResult.data.teachers.collection[0] ?? null
}

export const getTeacherSitemapResults = async (
  variables: IGQLPageVariables = {}
): Promise<ITeacherGQLResults> => {
  const query = `
    query TeacherSitemapResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 50
    ) {
      teachers: collectionPageQueryTeachers (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
      ) {
        collection {
          ${MINIMAL_TEACHER_FIELDS}
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    teachers: ITeacherGQLResults
  }>(query, variables)

  return graphqlResult.data.teachers
}

// Mapped to reducer
export interface IGQLTeacherVariables extends IGQLPageVariables {
  areaKey?: IAreaKey
  disciplineIds?: number[]
  sessionTypeKeys?: string[]
}

export const buildGqlVariablesFromTeacherFilters = (
  teacherFiltersState: ITeacherFiltersState
): IGQLTeacherVariables => {
  const { pageIndex, areaKey, disciplineIds, sessionTypeKeys, multiSearch } =
    teacherFiltersState

  return {
    pageIndex,
    ...(areaKey && { areaKey }),
    ...(disciplineIds.length && { disciplineIds }),
    ...(sessionTypeKeys.length && { sessionTypeKeys }),
    ...(multiSearch && { multiSearch }),
  }
}

export const MINIMAL_TEACHER_FIELDS = `
  id
  _id
  fullName
  name
  avatarPath
  authUserFavorite
`
