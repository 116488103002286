import {
  IPropertyKey,
  IPropertyKeySlug,
  IPropertyValue,
  IPropertyValueDiscipline,
} from '@/types/main'
import { fetchGraphQL } from '@/utils/api-helpers'

export interface IPropertyResultsVariables {
  slugs: IPropertyKeySlug[]
}
export const getPropertyResults = async <T extends IPropertyValue>(
  variables: IPropertyResultsVariables
): Promise<IPropertyKey<T>[]> => {
  const query = `
    query PropertyResults (
      $slugs: [String]
    ) {
      propertyKeys (
        slug_list: $slugs
      ) {
        ${MINIMAL_PROPERTY_FIELDS}
        propValues {
          ${MINIMAL_PROPERTY_FIELDS}
          weight
          childrenIdentifiers
          parentIdentifier
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{ propertyKeys: IPropertyKey<T>[] }>(
    query,
    variables
  )

  return graphqlResult.data.propertyKeys
}

export type IPropertyValueResultsVariables = {
  propKeySlug: IPropertyKeySlug
}
export const getPropertyValueResults = async <T extends IPropertyValue>(
  variables: IPropertyValueResultsVariables
): Promise<T[]> => {
  const query = `
    query PropertyValueResults (
      $propKeySlug: String!
    ) {
      propertyValues: collectionPageQueryPropertyValues (
        propKey_slug: $propKeySlug
      ) {
        ${MINIMAL_PROPERTY_FIELDS}
        weight
        childrenIdentifiers
        parentIdentifier
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    propertyValues: T[]
  }>(query, variables)

  return graphqlResult.data.propertyValues
}

export interface IPropertyValueResultVariables {
  propKeySlug: IPropertyKeySlug
  slug: string
  parentSlug?: string
}
export const getPropertyValueResult = async <T extends IPropertyValue>(
  variables: IPropertyValueResultVariables
): Promise<T> => {
  const query = `
    query PropertyValueResult (
      $propKeySlug: String!
      $slug: String!
      $parentSlug: String
    ) {
      propertyValues: collectionPageQueryPropertyValues (
        propKey_slug: $propKeySlug
        slug: $slug
        parent_slug: $parentSlug
      ) {
        ${MINIMAL_PROPERTY_FIELDS}
        parent {
          ${MINIMAL_PROPERTY_FIELDS}
          parent {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
        children {
          ${MINIMAL_PROPERTY_FIELDS}
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    propertyValues: T[]
  }>(query, variables)

  return graphqlResult.data.propertyValues[0] ?? null
}

export const getPropertyValuePillarResults = async (
  variables: unknown = {}
): Promise<IPropertyValueDiscipline[]> => {
  const query = `
    query PropertyValuePillarResults (
      $propKeySlug: String = "discipline"
    ) {
      propertyValues: collectionPageQueryPropertyValues (
        propKey_slug: $propKeySlug
        exists: { parent: false }
      ) {
        ${MINIMAL_PROPERTY_FIELDS}
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    propertyValues: IPropertyValueDiscipline[]
  }>(query, variables)

  return graphqlResult.data.propertyValues
}

export const MINIMAL_PROPERTY_FIELDS = `
  _id
  title
  slug
  misc
`
