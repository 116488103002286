import {
  KeyedMutator,
  Middleware,
  SWRConfiguration,
  SWRHook,
  unstable_serialize,
  useSWRConfig,
} from 'swr'
import {
  SWRInfiniteKeyLoader,
  SWRInfiniteResponse,
  unstable_serialize as unstable_serialize_infinite,
} from 'swr/infinite'

export const swrImmutableConfig: SWRConfiguration = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

interface IGenericPageResults<T> {
  collection: T[]
}
export interface IGenericPageItemResults {
  id: string
}

export const buildGenericSwrMutate = (
  mutateFn: KeyedMutator<any>,
  childKey?: string
): KeyedMutator<any> => {
  const optimisticDataFn = (optimisticD, childKey) => (currentData?) =>
    updSWRData(currentData, optimisticD, childKey)
  const populateCacheFn = (optimisticD, childKey) => (_, currentData) =>
    updSWRData(currentData, optimisticD, childKey)

  return async (data, opts: any) => {
    if (opts?.optimisticData) {
      return await mutateFn(data, {
        ...opts,
        optimisticData: optimisticDataFn(opts.optimisticData, childKey),
        populateCache: populateCacheFn(opts.optimisticData, childKey),
      })
    }

    return await mutateFn(data, opts)
  }
}

const updSWRData = <T extends IGenericPageItemResults>(
  currPageResults: IGenericPageResults<T>,
  optimisticData: T,
  childKey?: string
) => {
  const itemIndex = currPageResults.collection.findIndex(
    (item) => (childKey ? item[childKey] : item).id === optimisticData.id
  )
  const updCollection = [
    ...currPageResults.collection.slice(0, itemIndex),
    ...[
      childKey
        ? { ...currPageResults.collection[itemIndex], [childKey]: optimisticData }
        : optimisticData,
    ],
    ...currPageResults.collection.slice(itemIndex + 1),
  ]

  return {
    ...currPageResults,
    collection: updCollection,
  }
}

export const buildGenericSwrMutateKey = (
  mutateFn: KeyedMutator<any>
): KeyedMutator<any> => {
  const optimisticDataFn = (optimisticD) => (currentData?) =>
    updSWRKeyData(currentData, optimisticD)
  const populateCacheFn = (optimisticD) => (_, currentData) =>
    updSWRKeyData(currentData, optimisticD)

  return async (data, opts: any) => {
    if (opts?.optimisticData) {
      return await mutateFn(data, {
        ...opts,
        optimisticData: optimisticDataFn(opts.optimisticData),
        populateCache: populateCacheFn(opts.optimisticData),
      })
    }

    return await mutateFn(data, opts)
  }
}

const updSWRKeyData = <T extends IGenericPageItemResults>(
  currPageResults: IGenericPageResults<T>[],
  optimisticData: T
) => {
  const { key, itemIndex } = Object.entries(currPageResults).reduce(
    (indexes, [key, itemResults]) => {
      if (indexes.key) {
        return indexes
      }

      // Loop items
      const itemIndex = itemResults.collection.findIndex(
        (item) => item.id === optimisticData.id
      )

      return itemIndex !== -1 ? { key, itemIndex } : indexes
    },
    { key: null, itemIndex: null } as {
      key: string
      itemIndex: number
    }
  )

  if (key === null) {
    return currPageResults
  }

  const updLessonCollection = [
    ...currPageResults[key].collection.slice(0, itemIndex),
    optimisticData,
    ...currPageResults[key].collection.slice(itemIndex + 1),
  ]

  return {
    ...currPageResults,
    [key]: { collection: updLessonCollection },
  }
}

export const buildGenericSwrMutateInfinite = (
  mutateFn: KeyedMutator<any>,
  childKey?: string
): KeyedMutator<any> => {
  const optimisticDataFn = (optimisticD, childKey) => (currentData?) =>
    updSWRInfiniteData(currentData, optimisticD, childKey)
  const populateCacheFn = (optimisticD, childKey) => (_, currentData) =>
    updSWRInfiniteData(currentData, optimisticD, childKey)

  return async (data, opts: any) => {
    if (opts?.optimisticData) {
      return await mutateFn(data, {
        ...opts,
        optimisticData: optimisticDataFn(opts.optimisticData, childKey),
        populateCache: populateCacheFn(opts.optimisticData, childKey),
      })
    }

    return await mutateFn(data, opts)
  }
}

const updSWRInfiniteData = <T extends IGenericPageItemResults>(
  currPageResults: IGenericPageResults<T>[],
  optimisticData: T,
  childKey?: string
) => {
  const { pageIndex, itemIndex } = currPageResults.reduce(
    (indexes, pageResult, pageIndex) => {
      if (indexes.pageIndex) {
        return indexes
      }

      // Loop pages
      const itemIndex = pageResult.collection.findIndex(
        (item) => (childKey ? item[childKey] : item).id === optimisticData.id
      )

      return itemIndex !== -1 ? { pageIndex, itemIndex } : indexes
    },
    { pageIndex: null, itemIndex: null } as { pageIndex: number; itemIndex: number }
  )

  if (pageIndex === null) {
    return currPageResults
  }

  const updPageCollection = [
    ...currPageResults[pageIndex].collection.slice(0, itemIndex),
    ...[
      childKey
        ? {
            ...currPageResults[pageIndex].collection[itemIndex],
            [childKey]: optimisticData,
          }
        : optimisticData,
    ],
    ...currPageResults[pageIndex].collection.slice(itemIndex + 1),
  ]

  return [
    ...currPageResults.slice(0, pageIndex),
    { ...currPageResults[pageIndex], collection: updPageCollection },
    ...currPageResults.slice(pageIndex + 1),
  ]
}

export const generateSwrInfiniteApiKey =
  <T extends object>(
    mainKey: string,
    fetcherArgs?: T,
    authUserUuid?: string
  ): SWRInfiniteKeyLoader =>
  (index: number, previousPageData: any) => {
    if (!mainKey) {
      return undefined
    }

    return [
      `api_${mainKey}`,
      {
        ...fetcherArgs,
        ...(index && { pageIndex: index + 1 }),
      },
      authUserUuid,
    ]
  }

export const serializeSwrInfiniteApiKey = <T extends object>(
  mainKey: string,
  fetcherArgs?: T,
  authUserUuid = 'unauth'
): string =>
  unstable_serialize_infinite(
    generateSwrInfiniteApiKey(mainKey, fetcherArgs, authUserUuid)
  )

export const serializeSwrApiKey = (
  mainKey: string,
  fetcherArgs: unknown = {},
  authUserUuid = 'unauth'
): string => unstable_serialize([`api_${mainKey}`, fetcherArgs, authUserUuid])

interface ISwrInfiniteState {
  isLoading: boolean
  isLoadingMore: boolean
  isRefreshing: boolean
  isLoaded: boolean
}
export const getSwrInfiniteStates = (
  pages: readonly unknown[],
  pagesSize: number,
  isLoading: boolean,
  isValidating: boolean
): ISwrInfiniteState => {
  const isLoadingMore =
    isLoading || (pagesSize > 0 && pages && typeof pages[pagesSize - 1] === 'undefined')
  const isRefreshing = isValidating && pagesSize > 0 && pages?.length === pagesSize

  return {
    isLoading,
    isLoadingMore,
    isRefreshing,
    isLoaded: !isLoading && !isLoadingMore && !isRefreshing,
  }
}

export const cacheDebugMiddleware: Middleware =
  (useSWRNext: SWRHook) => (key, fetcher, config) => {
    const { fallback, cache } = useSWRConfig()
    const serializedKey = unstable_serialize(key)

    if (typeof key === 'undefined' || ['local_user'].includes(key as string)) {
      return useSWRNext(key, fetcher, config)
    }

    if (!(serializedKey in fallback)) {
      console.warn(
        `[SWR Debug - No fallback][${
          cache.get(serializedKey) ? 'Cached' : 'Not cached'
        }]`,
        key,
        serializedKey
      )
      console.table(Object.keys(fallback))
    }

    return useSWRNext(key, fetcher, config)
  }

// TEMPORARY until https://github.com/vercel/swr/pull/2900
export type InfiniteKeyedMutator<T> = SWRInfiniteResponse<
  T extends (infer I)[] ? I : T
>['mutate']
