import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

interface IHeartIconProps extends ISvgProps {
  full?: boolean
}

export const HeartIcon: React.FC<IHeartIconProps> = ({ full, className }) => (
  <svg
    width="60"
    height="56"
    viewBox="0 0 60 56"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {full ? (
      <path d="M53.8049 5.49984C50.7525 2.52227 46.708 0.776752 42.4476 0.598195C38.1871 0.419639 34.0108 1.82063 30.7199 4.53234C30.5195 4.69493 30.2693 4.78367 30.0112 4.78367C29.7531 4.78367 29.5029 4.69493 29.3024 4.53234C26.7515 2.47809 23.6766 1.17867 20.4256 0.781045C17.1746 0.383424 13.8772 0.903475 10.9063 2.28237C7.93549 3.66127 5.40979 5.84399 3.61492 8.58363C1.82005 11.3233 0.827635 14.5105 0.749925 17.7848C0.592425 34.2098 21.1124 50.1623 27.3899 54.6623C28.1521 55.205 29.0644 55.4966 29.9999 55.4966C30.9355 55.4966 31.8478 55.205 32.6099 54.6623C38.9999 50.1623 59.4074 34.2098 59.2499 17.7848C59.2 15.4788 58.693 13.2055 57.7583 11.0968C56.8237 8.98802 55.4799 7.08565 53.8049 5.49984Z" />
    ) : (
      <path d="M29.9999 55.495C29.0645 55.4956 28.1523 55.2046 27.3899 54.6626C21.1124 50.1626 0.592425 34.2326 0.749925 17.7851C0.780689 14.4898 1.74338 11.2705 3.52672 8.49938C5.31006 5.72822 7.84122 3.51832 10.8276 2.12513C13.814 0.731947 17.1338 0.212357 20.403 0.626426C23.6723 1.04049 26.7576 2.37131 29.3024 4.46505C29.5029 4.62765 29.7531 4.71638 30.0112 4.71638C30.2693 4.71638 30.5195 4.62765 30.7199 4.46505C33.696 2.01795 37.3988 0.625447 41.2499 0.505051C45.9252 0.420554 50.4437 2.19111 53.8169 5.42943C57.1902 8.66774 59.1436 13.1102 59.2499 17.7851C59.4074 34.2326 38.9999 50.1626 32.6099 54.6626C31.8476 55.2046 30.9353 55.4956 29.9999 55.495ZM18.2774 5.00505C14.918 5.02784 11.6959 6.34086 9.27742 8.67255C8.02781 9.85205 7.02751 11.2702 6.33573 12.8432C5.64394 14.4161 5.27472 16.1119 5.24993 17.8301C5.11493 31.8026 24.0374 46.6301 29.3474 50.5001C29.5401 50.6408 29.7725 50.7167 30.0112 50.7167C30.2498 50.7167 30.4823 50.6408 30.6749 50.5001C35.9624 46.6076 54.8849 31.7801 54.7499 17.8076C54.7222 16.0878 54.3495 14.391 53.6538 12.818C52.9581 11.245 51.9536 9.82763 50.6999 8.65005C49.4428 7.44956 47.9607 6.50949 46.3389 5.88393C44.7171 5.25838 42.9876 4.95969 41.2499 5.00505C38.1601 5.11561 35.2098 6.31962 32.9249 8.40255C32.0967 9.15514 31.0178 9.57211 29.8987 9.57211C28.7796 9.57211 27.7007 9.15514 26.8724 8.40255C24.6454 6.35527 21.7714 5.15312 18.7499 5.00505H18.2774Z" />
    )}
  </svg>
)
